import React from 'react'
import Link from 'gatsby-link'
import Img from "gatsby-image"
import { graphql } from 'gatsby'

const BlogPost = ({ node }) => {
  return (
    <div style={{
      marginBottom: '1.5rem',
      padding: '1.5rem',
      border: '1px solid #ccc'
    }}>
      <h3><Link to={node.slug}>{node.title}</Link></h3>
      <p>{node.publishDate}</p>
      <div>
        <div>
          <Img resolutions={node.heroImage.resolutions} />
        </div>
        <div>{node.body.childMarkdownRemark.excerpt}</div>
      </div>
    </div>
  )
}

const IndexPage = (props) => {

  console.log(props)
  return (
    <div>
      {props.data.allContentfulBlogPost.edges.map((edge) => <BlogPost key={edge.node.id} node={edge.node} />)}
    </div>
  )
}

export default IndexPage

export const pageQuery = graphql`
    query pageQuery {
        allContentfulBlogPost(
            filter: {
                node_locale: {eq: "en-US"}
            },
            sort: {
                fields: [createdAt], order: DESC
            }
        ) {
            edges {
                node {
                    id
                    title
                    slug
                    publishDate(formatString: "MMMM DD, YYYY")
                    heroImage {
                        resolutions(width: 300) {
                            ...GatsbyContentfulResolutions
                        }
                    }
                    body {
                        childMarkdownRemark {
                            excerpt
                        }
                    }
                }
            }
        }
    }
`




// import React from 'react'
// import { Link, graphql } from 'gatsby'
// import Img from 'gatsby-image'
// import get from 'lodash/get'
// import Helmet from 'react-helmet'

// import Layout from '../components/layout'
// import { rhythm } from '../utils/typography'

// class BlogIndex extends React.Component {
//   render() {
//     const siteTitle = get(this, 'props.data.site.siteMetadata.title')
//     const siteDescription = get(
//       this,
//       'props.data.site.siteMetadata.description'
//     )
//     const posts = get(this, 'props.data.allMarkdownRemark.edges')

//     return (
//       <Layout location={this.props.location}>
//         <Helmet
//           htmlAttributes={{ lang: 'en' }}
//           meta={[{ name: 'description', content: siteDescription }]}
//           title={siteTitle}
//         />
//         {posts.map(({ node }) => {
//           const title = get(node, 'frontmatter.title') || node.fields.slug
//           return (
//             <div key={node.fields.slug}>
//               <h3
//                 style={{
//                   marginBottom: rhythm(1 / 4),
//                 }}
//               >
//                 <Link style={{ boxShadow: 'none' }} to={node.fields.slug}>
//                   {title}
//                 </Link>
//               </h3>
//               <small>{node.frontmatter.date}</small>
//               <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
//             </div>
//           )
//         })}
//       </Layout>
//     )
//   }
// }

// export default BlogIndex

// export const pageQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//         description
//       }
//     }
//     allContentfulBlogPost(
//       sort: {
//         fields: [publishDate], order: DESC
//       }
//     ) {
//       edges {
//         node {
//           id
//           title
//           slug
//           publishDate(formatString: "MMM DD, YYYY")
//           heroImage {
//             resolutions(width: 300) {
//               ...GatsbyContentfulResolutions
//             }
//           }
//           body {
//             childMarkdownRemark {
//               excerpt
//             }
//           }
//         }
//       }
//     }
//   }
// `
